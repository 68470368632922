import React, { useState, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Label, PhotoCamera } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
  import LocationPicker from 'react-location-picker';
import WidgetGeoLocation from './WidgetGeoLocation';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import SvgIcon from '@material-ui/core/SvgIcon';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import MenuList from '@material-ui/core/MenuList';

const options = ['Create a merge commit', 'Squash and merge', 'Rebase and merge'];

export default function WidgetFieldFactory({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  widget,
  widgettype,
  setFieldValue,
}) {
  const {
    detail: {
      initiative_obj: { modules },
    },
  } = widget;

  const defaultPosition = {
    lat: 41.8968348,
    lng: 12.4866357,
  };

  const [imagePreviewUrl, setImagePreviewUrl] = useState(undefined);

  const useStyles = makeStyles(theme => ({
    root: {
      color: '#3B84B5',
      '& > *': {
        margin: theme.spacing(1),
      },
      '& button': {
        backgroundColor: '#3B84B5',
        color: '#fff',
        margin: '4px',
      },
    },
    map: {
      width: '100%',
    },
    previewWrapper: {
      width: '100%',
      marginBottom: '20px',
      '& >.imageWrapper': {
        maxWidth: '300px',
        minHeight: '200px',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        '& > img': {
          width: '100%',
          height: 'auto',
        },
      },
    },
    uploadBtn: {
      color: '#ffffff',
      backgroundColor: '#3B84B5',
      margin: theme.spacing(1),
      '& > button': {
        color: '#ffffff',
        backgroundColor: '#3B84B5',
        '&:hover': {
          backgroundColor: '#3B84B5',
          opacity: '0.5',
        },
      },
    },
    action: {
      color: '#3B84B5',
    },
    input: {
      display: 'none',
    },
  }));

  const classes = useStyles();

  const modulesVisible = modules.filter(module => {
    return module.visible === true;
  });

  const fileAsBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        let rr = file.type.indexOf();
        if (file.type.indexOf('image') != -1) {
          setImagePreviewUrl(reader.result);
          handleToggle();
        }
      };
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const filesInput = [];

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      {modulesVisible &&
        modulesVisible.map((module, index) => {
          const {
            id,
            type_obj: { name },
            label,
          } = module;
          const fieldName = name + id;

          switch (name) {
            case 'image':
              filesInput.push({
                fieldName: fieldName,
                name: name,
                index: index,
                type: 'file',
                accept: 'image/*',
                id: 'icon-button-file',
                iconLabel: PhotoCamera,
              });
              break;

            case 'audio':
              filesInput.push({
                fieldName: fieldName,
                name: name,
                index: index,
                type: 'file',
                accept: 'audio/*',
                id: 'contained-button-file-audio',
                iconLabel: AudiotrackIcon,
              });
              break;

            case 'video':
              filesInput.push({
                fieldName: fieldName,
                name: name,
                index: index,
                type: 'file',
                accept: 'video/*',
                id: 'contained-button-file-video',
                iconLabel: VideoCallIcon,
              });
              break;
            case 'geopoint':
              return (
                <div key={`module-` + index} className={classes.map}>
                  {touched[fieldName] && errors[fieldName] && <div>{errors[fieldName]}</div>}
                  <WidgetGeoLocation
                    name={fieldName}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>
              );
              break;
            default:
              return (
                <TextField
                  autoFocus={index === 0 ? true : false}
                  margin="dense"
                  id={fieldName}
                  variant={widgettype != 'dialog' ? 'filled' : 'standard'}
                  name={fieldName}
                  label={label}
                  type="text"
                  fullWidth={name != 'long_text' ? false : true}
                  className={'' + module.type_obj.name + ''}
                  key={`module-` + index}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={errors[fieldName] && touched[fieldName]}
                  helperText={errors[fieldName] && touched[fieldName] && errors[fieldName]}
                  value={values[fieldName] || ''}
                  multiline
                />
              );
          }
        })}

      <ButtonGroup
        className={classes.uploadBtn}
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
          endIcon={<ArrowDropDownIcon>send</ArrowDropDownIcon>}
        >
          Carica un file
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {filesInput.map(item => (
                    <div key={`module-` + item.index} className={classes.root} id={item.fieldName}>
                      <input
                        className={classes.input}
                        accept={item.accept}
                        key={`module-` + item.index}
                        id={item.id}
                        name={item.fieldName}
                        type="file"
                        onChange={async event =>
                          setFieldValue(
                            item.fieldName,
                            await fileAsBase64(event.currentTarget.files[0])
                          )
                        }
                        onBlur={handleBlur}
                      />

                      <IconButton
                        htmlFor={item.id}
                        className={classes.action}
                        aria-label={`upload ` + item.name}
                        component="label"
                      >
                        <SvgIcon component={item.iconLabel} />
                      </IconButton>
                    </div>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <div className={classes.previewWrapper}>
        {console.log('imagePreviewUrl', imagePreviewUrl)}
        {imagePreviewUrl != undefined ? (
          <div className="imageWrapper">
            <img className="thumb" src={imagePreviewUrl} alt="..." />
          </div>
        ) : (
          false
        )}
      </div>
    </>
  );
}
