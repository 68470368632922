import http, { defaultParams } from './configureApi';

const GAMIFICATION_HISTORY_PATH = process.env.CT_API_GAMIFICATION_HISTORY_PATH;
const GAMIFICATION_HISTORY_PATH_ID = process.env.CT_API_GAMIFICATION_HISTORY_PATH_ID;

export const getGamificationHistoryList = (brandId, limit, params) => {
  return http.get(GAMIFICATION_HISTORY_PATH, {
    defaultParams, ...{
      params: {
        brands: brandId,
        ...((typeof limit !== 'undefined' && limit !== null) ? { limit: limit } : { limit: 5 }),
        ...((typeof params !== 'undefined' && params !== null) ? params : {})
        // ...params,
      },
    },
  });
};

export const getGamificationHistoryDetail = (userId, brandId, range, params) => {
  return http.get(GAMIFICATION_HISTORY_PATH_ID.replace(":id", userId), {
    defaultParams, ...{
      params: {
        brands: brandId,
        ...((typeof range !== 'undefined' && range !== null) ? { range: range } : { range: 5 }),
        ...((typeof params !== 'undefined' && params !== null) ? params : {})
        // ...params,
      },
    },
  });
};
