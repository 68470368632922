import axios from 'axios';
import { APP_CONFIG } from '../constants';
import http from "./configureApi";
import {userSSOFetch, userSSOFetchFail} from "../store/actions/UserAction";
import Cookies from "js-cookie";

const USER_PATH = process.env.CT_API_USER_PATH
const USER_PATH_ID = process.env.CT_API_USER_PATH_ID

//not used
export const getUserSSO = (authUrl, authCookie) => {
  const http = axios.create({
    timeout: APP_CONFIG.REQUEST_TIMEOUT
  })
  return http.get(authUrl, {
    params: {
      session: authCookie
    }
  })
}

export const getUser = (userId) => {
  if(typeof userId === 'object'){//ssoUser -> sso_username
    return http.get(USER_PATH, {
      params: userId
    });
  }else{//ctUser
    const path = USER_PATH_ID.replace(":id", userId);
    return http.get(path);
  }
};

export const getUserServices = (widget, params) => {
  return http.get(APP_CONFIG.SERVICES_BASE_URL, {params});
};

export const jwtFilters = (filter, dispatch, action2Call, widget) => {
  document.addEventListener(filter, function(event) {
    dispatch(action2Call(widget.detail));
  });
};

export const jwtFiltersOld = (filter, dispatch, action2Call, widget) => {
  function callback(mutations, observer) {
    mutations.forEach(function(mutation) {
      if (mutation.attributeName === "class") {
        if ($(mutation.target).hasClass('logged')){
          dispatch(action2Call(widget.detail));
        }
      }
    });
  }
  /*
  const mutationObserver = new MutationObserver(callback);
  mutationObserver.observe(
      document.body,
      {attributes: true}
  );*/
  
};

export const socialLogin = () => {
  GeleSocial.openLoginModal(undefined, undefined, {"forward":false, "origin": RenderSocial.FUNNEL_ORIGIN_PREFIX+"ACC_SLIM", "urlToken": RenderSocial.FUNNEL_TOKEN_LOGIN});
};
