import React, { useState } from 'react';
import moment from 'moment';
import { ugcSave } from '../../../store/actions/UgcAction';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import DialogFormContribute from './DialogFormContribute';
import EmbedFormContribute from './EmbedFormContribute';
import DialogComponent from '../../dialog/DialogComponent';
import { UGC_SAVE_SUCCESS } from '../../../store/actions/UgcAction';
import { Spinner } from '../../layout/Spinner';
import Button from '@material-ui/core/Button';

import OpenContributeForm from '../card/WidgetCard';
import { userSSOFetch, userSSOFetchFail } from '../../../store/actions/UserAction';
import { jwtFilters, socialLogin } from '../../../api/UserApi';
import { APP_CONFIG } from '../../../constants';

const WidgetContribute = ({
  user,
  ssoToken,
  ssoServices,
  widget,
  linktext,
  linkclass,
  widgettype,
}) => {
  const [showForm, setShowForm] = useState(true);
  const [dialogMsg, setDialogMsg] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('success');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [disabledForm, setDisabledForm] = useState('');

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const {
    loading,
    error,
    detail: {
      title: widgetTitle,
      description,
      user_obj,
      type_obj: { name: typeName },
      initiative_obj: { modules },
    },
  } = widget;

  console.log(widget);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnClick = value => {
    socialLogin();
  };

  const handleSubmit = (values, actions, widget) => {
    setDisabledForm('disabled');
    setShowSpinner(true);

    // FILTRO SOLO I MODULI VALORIZZATI
    const modulesFiltered = widget.detail.initiative_obj.modules.filter(
      module => values[module.type_obj.name + module.id] !== undefined
    );

    const modules = modulesFiltered.map(module => {
      if (
        module.type_obj.name === 'audio' ||
        module.type_obj.name === 'image' ||
        module.type_obj.name === 'video'
      ) {
        const binaryData = values[module.type_obj.name + module.id];
        return {
          [module.type_obj.name]: binaryData,
          initiativemodule: module.id,
        };
      } else
        return {
          value: values[module.type_obj.name + module.id],
          initiativemodule: module.id,
        };
    });

    const ugc = {
      user: user,
      user_subscriber: ssoServices,
      user_registered: true,
      sso_username: ssoToken,
      creation_date: moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
      initiative: widget.detail.initiative_obj.id,
      brand: widget.detail.initiative_obj.brands[0],
      modules: modules,
      completed: false,
    };

    dispatch(ugcSave(ugc, widget.detail.uuid))
      .then(data => printDialog(data))
      .then(() => setShowSpinner(false));
    actions.setSubmitting(false);
  };

  const fieldValidator = module => {
    const {
      type_obj: { title, name },
    } = module;

    switch (name) {
      case 'email':
        return Yup.string().email('Inserire una mail valida');
      case 'image':
      case 'audio':
      case 'geopoint':
      case 'video':
      case 'description':
      default:
        return Yup.string().label(title);
    }
  };

  const getSchemaObject = modules => {
    let schemaObject = {};
    if (modules) {
      modules.forEach(module => {
        const {
          id,
          visible,
          mandatory: required,
          type_obj: { name },
        } = module;
        const fieldName = name + id;
        if (visible === true) {
          schemaObject[fieldName] =
            required === true
              ? fieldValidator(module).required('Campo obbligatorio')
              : fieldValidator(module);
        }
      });
    }
    return Yup.object().shape(schemaObject);
  };

  const getInitialValues = modules => {
    let initialValues = {};

    if (modules) {
      modules.forEach(module => {
        const {
          id,
          visible,
          required,
          type_obj: { name },
        } = module;
        const fieldName = name + id;
        if (visible === true) {
          initialValues[fieldName] = undefined;
        }
      });
    }
    return initialValues;
  };

  const getPointFactor = () => {
    return widget.detail.initiative_obj.point_factor
      ? widget.detail.initiative_obj.point_factor
      : widget.detail.brand.point_factor;
  };

  const printDialog = payload => {
    if (payload.type != UGC_SAVE_SUCCESS) {
      setDialogSeverity('error');
      setDialogMsg('Qualcosa è andato storto! Riprova!');
      setDisabledForm('');
    } else {
      setDialogSeverity('success');
      setDialogMsg('Contributo inviato! Grazie!');
      setShowForm(true);
    }
    setDialogOpen(true);
  };

  const dialogHandleClose = value => {
    setDialogOpen(false);
  };

  jwtFilters('GeleSocial.getJWT.success', dispatch, userSSOFetch, widget);

  var isUserEnabled =
    !widget.detail.initiative_obj.to_subscribers ||
    (widget.detail.initiative_obj.to_subscribers && ssoServices);

  return (
    <>
      <DialogComponent
        msg={dialogMsg}
        severity={dialogSeverity}
        open={dialogOpen}
        onClose={dialogHandleClose}
      />
      {user != null && typeof user != 'undefined' ? (
        <>
          {showForm && (
            <>
              {isUserEnabled ? (
                <>
                  {showSpinner && <Spinner />}

                  <Formik
                    initialValues={getInitialValues(modules)}
                    validationSchema={getSchemaObject(modules)}
                    onSubmit={(values, action) => {
                      handleSubmit(values, action, widget);
                    }}
                  >
                    {props => (
                      <form onSubmit={props.handleSubmit}>
                        {widgettype === 'dialog' ? (
                          <>
                            <OpenContributeForm
                              title={widget.detail.initiative_obj.title}
                              image={widget.detail.initiative_obj.medias[0].image}
                              description={widget.detail.initiative_obj.summary}
                              widgetName={widget.detail.type_obj.name}
                              cta={
                                <Button
                                  variant="contained"
                                  className={
                                    linkclass
                                      ? linkclass + ' ' + 'contribute__cta'
                                      : 'contribute__cta'
                                  }
                                  onClick={handleClickOpen}
                                >
                                  {' '}
                                  {linktext ? linktext : 'Invia contributo'}{' '}
                                </Button>
                              }
                              type={widgettype}
                            />

                            <DialogFormContribute
                              open={open}
                              error={error}
                              formikProps={props}
                              handleClose={handleClose}
                              widget={widget}
                              type={widgettype}
                              disabledForm={disabledForm}
                              pointFactor={getPointFactor()}
                            />
                          </>
                        ) : (
                          <>
                            <EmbedFormContribute
                              handleClose={handleClose}
                              type={widgettype}
                              formikProps={props}
                              error={error}
                              widget={widget}
                              disabledForm={disabledForm}
                              pointFactor={getPointFactor()}
                            />

                            {/* <p>Questo contribute ti fa acquisire {getPointFactor()} punti</p> */}
                          </>
                        )}
                      </form>
                    )}
                  </Formik>
                </>
              ) : (
                <>
                  {widgettype === 'dialog' ? (
                    <OpenContributeForm
                      title={widget.detail.initiative_obj.title}
                      image={widget.detail.initiative_obj.medias[0].image}
                      description={widget.detail.initiative_obj.summary}
                      pointFactor={getPointFactor()}
                      widgetName={widget.detail.type_obj.name}
                      cta={
                        <Button
                          variant="contained"
                          onClick={handleOnClick}
                          className={
                            linkclass ? linkclass + ' ' + 'contribute__cta' : 'contribute__cta'
                          }
                        >
                          Accedi
                        </Button>
                      }
                      type={widgettype}
                    />
                  ) : (
                    <Button
                      variant="contained"
                      onClick={handleOnClick}
                      className={
                        linkclass ? linkclass + ' ' + 'contribute__cta' : 'contribute__cta'
                      }
                    >
                      Accedi per contribuire all'iniziativa
                    </Button>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {widgettype === 'dialog' ? (
            <OpenContributeForm
              title={widget.detail.initiative_obj.title}
              image={widget.detail.initiative_obj.medias[0].image}
              description={widget.detail.initiative_obj.summary}
              pointFactor={getPointFactor()}
              widgetName={widget.detail.type_obj.name}
              cta={
                <Button
                  variant="contained"
                  onClick={handleOnClick}
                  className={linkclass ? linkclass + ' ' + 'contribute__cta' : 'contribute__cta'}
                >
                  Accedi
                </Button>
              }
              type={widgettype}
            />
          ) : (
            <Button
              variant="contained"
              onClick={handleOnClick}
              className={linkclass ? linkclass + ' ' + 'contribute__cta' : 'contribute__cta'}
            >
              Accedi per contribuire all'iniziativa
            </Button>
          )}
        </>
      )}
    </>
  );
};
export default WidgetContribute;
