import React, {Component} from 'react';
import { connect } from 'react-redux';
import { getInitiativeDetail } from '../../../store/selectors/InitiativeSelector';
import { getGamificationHistory } from '../../../store/selectors/GamificationSelector';
import { gamificationHistoryListFetch, gamificationHistoryDetailFetch } from '../../../store/actions/GamificationAction';
import { withStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
});

class WidgetRanking extends Component {
  componentDidMount() {
    this.props.componentDidMount();
  }

 componentDidUpdate(prevProp) {
    this.props.componentDidUpdate(prevProp);
  }

  render () {
    const { gamificationList, user} = this.props;
    const {classes} = this.props;

    let gamification = (gamificationList !== undefined && gamificationList.results !== undefined) ? gamificationList.results : gamificationList;

    if (gamification !== undefined && gamification.length) {
      return (
        <List className={classes.root}>
          {gamification.map((gamification, index) => {
            let altImg = gamification.user_obj.username;
            return(
              <>
              <ListItem
                key={gamification.id}
                alignItems="flex-start"
              >
                <ListItemAvatar>
                  <Avatar alt={altImg} src="/static/images/avatar/1.jpg" />
                </ListItemAvatar>
                <ListItemText
                  primary={altImg}
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        Punti totalizzati:{gamification.points}
                        <br />
                        Posizione:{(gamification.position+1)}
                        <br />
                        Badge:{gamification.badge.title}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
              </>
            );
          })}
        </List>
      );
    } else {
      // rimuovere
      return 'list gamification empty';
    }
  }
}

export default withStyles(styles, {withTheme: true})(connect(
  (state, props) => ({
    initiative: getInitiativeDetail(state),
    gamificationList: getGamificationHistory(state, props.widget.detail.uuid)
  }),
  (dispatch, props) => ({
    componentDidMount() {
        dispatch(gamificationHistoryListFetch(props.widget.detail.uuid, props.widget.detail.brand, props.range));
    },
    componentDidUpdate(prevProps) {
      if (prevProps.user !== props.user) {
        dispatch(gamificationHistoryDetailFetch(props.user, props.widget.detail.uuid, props.widget.detail.brand, props.range));
      }
    }
  }),
)(WidgetRanking));
