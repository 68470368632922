import { getGamificationHistoryList, getGamificationHistoryDetail } from '../../api/GamificationApi';
import {APP_CONFIG} from "../../constants";

export const GAMIFICATION_HISTORY_LIST_FETCH = "GAMIFICATION_HISTORY_LIST/fetch";
export const GAMIFICATION_HISTORY_LIST_FETCH_SUCCESS = "GAMIFICATION_HISTORY_LIST/fetch_success";
export const GAMIFICATION_HISTORY_LIST_FETCH_FAIL = "GAMIFICATION_HISTORY_LIST/fetch_fail";
export const GAMIFICATION_HISTORY_DETAIL_FETCH = "GAMIFICATION_HISTORY_DETAIL/fetch";
export const GAMIFICATION_HISTORY_DETAIL_FETCH_SUCCESS = "GAMIFICATION_HISTORY_DETAIL/fetch_success";
export const GAMIFICATION_HISTORY_DETAIL_FETCH_FAIL = "GAMIFICATION_HISTORY_DETAIL/fetch_fail";

export const gamificationHistoryListFetchSuccess = (widgetId, gamificationList) => ({ type: GAMIFICATION_HISTORY_LIST_FETCH_SUCCESS, payload: { gamificationList,  widgetId}});
export const gamificationHistoryListFetchFail = (widgetId, error) => ({ type: GAMIFICATION_HISTORY_LIST_FETCH_FAIL, payload: {error, widgetId} });
export const gamificationHistoryListFetch = (widgetId, brand, limit) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GAMIFICATION_HISTORY_LIST_FETCH, payload: {widgetId}});
      const { data: list } = await getGamificationHistoryList(brand.id, limit);
      dispatch(gamificationHistoryListFetchSuccess(widgetId, list));
    } catch(error) {
      console.log(error);
      dispatch(gamificationHistoryListFetchFail(widgetId, error));
    }
  }
};
export const gamificationHistoryDetailFetchSuccess = (widgetId, gamificationList) => ({ type: GAMIFICATION_HISTORY_DETAIL_FETCH_SUCCESS, payload: { gamificationList,  widgetId}});
export const gamificationHistoryDetailFetchFail = (widgetId, error) => ({ type: GAMIFICATION_HISTORY_DETAIL_FETCH_FAIL, payload: {error, widgetId} });
export const gamificationHistoryDetailFetch = (userId, widgetId, brand, range) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GAMIFICATION_HISTORY_DETAIL_FETCH, payload: {widgetId}});
      const { data: list } = await getGamificationHistoryDetail(userId, brand.id, range);
      dispatch(gamificationHistoryDetailFetchSuccess(widgetId, list));
    } catch(error) {
      console.log(error);
      dispatch(gamificationHistoryDetailFetchFail(widgetId, error));
    }
  }
};
