import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

const DialogProposalComponent = props => {
  const {
    open,
    handleClose,
    handleSubmit,
    disabledForm,
    errors,
    touched,
    handleBlur,
    handleChange,
    values,
  } = props;

  const useStyles = makeStyles(theme => ({
    root: {
      color: '#3B84B5',
    },
    title: {
      color: '#3B84B5',
      fontSize: '18px',
    },
    subTitle: {
      padding: '0px 24px',
      color: '#3B84B5',
    },
    fieldset: {
      border: 'none',
      color: '#3B84B5',
      padding: '0',
      '& .MuiInputLabel-formControl': {
        color: '#3B84B5',
      },

      '& .MuiInput-underline:after,& .MuiInput-underline:before': {
        borderColor: '#3B84B5',
      },
    },
    proposalForm: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
    },
    input: {
      flex: ' 1 1 auto',
      margin: theme.spacing(1),
    },
    actions: {
      color: '#3B84B5',
    },
  }));

  const handleSubmitDialog = event => {
    event.preventDefault();
    handleSubmit(event);
  };
  const classes = useStyles();

  return (
    <div>
      <Dialog
        className={classes.root}
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'md'}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle className={classes.title} id="form-dialog-title">
          Inviaci la tua proposta
        </DialogTitle>
        <DialogContentText className={classes.subTitle}>
          Compila questi campi per segnalarci un approfondimento di cui occuparci
        </DialogContentText>
        <form onSubmit={e => handleSubmit(e)}>
          <fieldset className={classes.fieldset} disabled={disabledForm}>
            <DialogContent className={classes.proposalForm}>
              <TextField
                autoFocus
                margin="dense"
                id="title"
                label="Titolo"
                type="text"
                className={classes.input}
                fullWidth={false}
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.title && touched.title}
                helperText={errors.title && touched.title && errors.title}
              />
              {/* <TextField
                autoFocus
                margin="dense"
                id="nome"
                label="Nome"
                type="text"
                className={classes.input}
                fullWidth={false}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name && touched.name}
                helperText={errors.name && touched.name && errors.name}
              />
              <TextField
                autoFocus
                margin="dense"
                id="cognome"
                label="Cognome"
                type="text"
                className={classes.input}
                fullWidth={false}
                value={values.surname}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.surname && touched.surname}
                helperText={errors.surname && touched.surname && errors.surname}
              />
              <TextField
                autoFocus
                margin="dense"
                id="email"
                label="Email"
                type="text"
                className={classes.input}
                fullWidth={false}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.email && touched.email}
                helperText={errors.email && touched.email && errors.email}
              /> */}

              <TextField
                margin="dense"
                id="summary"
                label="Descrivi la tua idea"
                type="text"
                className={classes.input}
                fullWidth
                value={values.summary}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.summary && touched.summary}
                helperText={errors.summary && touched.summary && errors.summary}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary" className={classes.actions}>
                Annulla
              </Button>
              <Button type="submit" color="primary" className={classes.actions}>
                Invia
              </Button>
            </DialogActions>
          </fieldset>
        </form>
      </Dialog>
    </div>
  );
};

export default DialogProposalComponent;
