import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  root: {
    color: '#3B84B5',
    '& .short_text': {
      flex: ' 1 1 auto',
      margin: theme.spacing(1),
    },
    '& .email': {
      flex: ' 1 1 auto',
      margin: theme.spacing(1),
    },
    '& .long_text': {
      margin: theme.spacing(1),
    },
  },
  fieldset: {
    border: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '24px',
    justifyContent: 'space-between',
    '& .MuiInputLabel-formControl': {
      color: '#3B84B5',
    },
    '& .MuiInput-underline:after': {
      borderColor: '#3B84B5',
    },
  },
  embedActions: {
    width: '100%',
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > span': {
      color: '#3c84b6',
      marginRight: 'auto',
    },
  },
  delete: {
    backgroundColor: '#515151',
    color: '#fff',
    margin: '8px',
  },
  send: {
    backgroundColor: '#3B84B5',
    color: '#fff',
    margin: '8px',
  },
  actions: {
    color: '#3B84B5',
  },
}));

const EmbedProposalComponent = props => {
  const {
    handleSubmit,
    disabledForm,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleClose,
    values,
  } = props;
  const classes = useStyles();
  return (
    <Container fixed className={classes.root}>
      <form onSubmit={e => handleSubmit(e)}>
        <fieldset className={classes.fieldset} disabled={disabledForm}>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Titolo"
            type="text"
            fullWidth
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.title && touched.title}
            helperText={errors.title && touched.title && errors.title}
          />
          <TextField
            margin="dense"
            id="summary"
            label="Sommario"
            type="text"
            fullWidth
            value={values.summary}
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.summary && touched.summary}
            helperText={errors.summary && touched.summary && errors.summary}
          />
          {/* <Button type="submit" color="primary">
            Invia
          </Button> */}

          <div className={classes.embedActions}>
            <Button className={classes.delete} size="medium" onClick={handleClose} color="primary">
              ANNULLA
            </Button>
            <Button className={classes.send} size="medium" onClick={handleSubmit} color="primary">
              INVIA
            </Button>
          </div>
        </fieldset>
      </form>
    </Container>
  );
};

export default EmbedProposalComponent;
