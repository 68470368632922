import {
  GAMIFICATION_HISTORY_LIST_FETCH,
  GAMIFICATION_HISTORY_LIST_FETCH_FAIL,
  GAMIFICATION_HISTORY_LIST_FETCH_SUCCESS,
  GAMIFICATION_HISTORY_DETAIL_FETCH,
  GAMIFICATION_HISTORY_DETAIL_FETCH_FAIL,
  GAMIFICATION_HISTORY_DETAIL_FETCH_SUCCESS
} from '../actions/GamificationAction';

export const GAMIFICATION_HISTORY_DEFAULT = {
  loading: false,
  data: {}
};

const INITIAL_STATE = {
  widgetGamification: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GAMIFICATION_HISTORY_LIST_FETCH_FAIL:
      return gamificationHistoryListFetchFail(state, action);
    case GAMIFICATION_HISTORY_LIST_FETCH:
      return gamificationHistoryListFetch(state, action);
    case GAMIFICATION_HISTORY_LIST_FETCH_SUCCESS:
      return gamificationHistoryListFetchSuccess(state, action);
      case GAMIFICATION_HISTORY_DETAIL_FETCH_FAIL:
      return gamificationHistoryDetailFetchFail(state, action);
    case GAMIFICATION_HISTORY_DETAIL_FETCH:
      return gamificationHistoryDetailFetch(state, action);
    case GAMIFICATION_HISTORY_DETAIL_FETCH_SUCCESS:
      return gamificationHistoryDetailFetchSuccess(state, action);
    default:
      return state;
  }
};

const gamificationHistoryListFetchFail = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
    widgetGamification: { ...state.widgetGamification, [widgetId]: GAMIFICATION_HISTORY_DEFAULT },
}};

const gamificationHistoryListFetch = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
    widgetGamification: {
    ...state.widgetGamification,
    [widgetId]: {...state.widgetGamification[widgetId], loading: GAMIFICATION_HISTORY_DEFAULT.loading, data: GAMIFICATION_HISTORY_DEFAULT.data }
    },
}};

const gamificationHistoryListFetchSuccess = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
    widgetGamification: {
    ...state.widgetGamification,
    [widgetId]: {...state.widgetGamification[widgetId], loading: false, data: action.payload.gamificationList }
  },
}};

const gamificationHistoryDetailFetchFail = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
    widgetGamification: { ...state.widgetGamification, [widgetId]: GAMIFICATION_HISTORY_DEFAULT },
}};

const gamificationHistoryDetailFetch = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
    widgetGamification: {
    ...state.widgetGamification,
    [widgetId]: {...state.widgetGamification[widgetId], loading: GAMIFICATION_HISTORY_DEFAULT.loading, data: GAMIFICATION_HISTORY_DEFAULT.data }
    },
}};

const gamificationHistoryDetailFetchSuccess = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
    widgetGamification: {
    ...state.widgetGamification,
    [widgetId]: {...state.widgetGamification[widgetId], loading: false, data: action.payload.gamificationList }
  },
}};
