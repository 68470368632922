import AppStore from './store/AppStore';
import ReactDOM from 'react-dom';
import React from 'react';
import { WidgetHtmlElement } from './WidgetHtmlElement';

//Define Custom Element
customElements.define('ct-widget', WidgetHtmlElement);

//Define Html5 Entry point
const widgetListDom = document.querySelectorAll('[data-type="widget"]');
if (widgetListDom && widgetListDom.length > 0) {
  widgetListDom.forEach(widgetDom => {
    let props = {};
    Object.keys(widgetDom.dataset).forEach(function (key) {
      props[key] = widgetDom.dataset[key];
    });
    // console.log(props);
    ReactDOM.render(<AppStore {...props} />, widgetDom);
  });
}
