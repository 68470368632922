import { WIDGET_FETCH, WIDGET_FETCH_FAIL, WIDGET_FETCH_SUCCESS } from '../actions/WidgetAction';
import moment from 'moment';


const INITIAL_STATE = {
  widgets: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WIDGET_FETCH:
      return widgetFetch(state, action);
    case WIDGET_FETCH_SUCCESS:
      return widgetFetchSuccess(state, action);
    case WIDGET_FETCH_FAIL:
      return widgetFetchFail(state, action);
    default:
      return state;
  }
};

const widgetFetch = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
    ...state,
    widgets: { ...state.widgets, [widgetId]: { loading: true, detail: null, error: null } },
  };
};

const widgetFetchSuccess = (state, action) => {
  const widgetId = action.payload.widgetId;
  let widget = action.payload.widget.results[0];
  if (action.payload.widget && typeof action.payload.widget.results && action.payload.widget.results.length > 0) {
    const initiative = widget.initiative_obj;

    const startDate = (initiative.start_date) ? moment(initiative.start_date) : null;
    const endDate = (initiative.end_date) ? moment(initiative.end_date) : null;
    const now = moment();

    const active =
      ((startDate !== null && endDate !== null
        && startDate.isBefore(now) && endDate.isAfter(now)) || (endDate !== null && endDate.isAfter(now)));
    widget = (initiative.published === true && active === true) ? widget : null;
  }
  return {
    ...state,
    widgets: {
      ...state.widgets,
      [widgetId]: { loading: false, detail: widget, error: null },
    },
  };
};

const widgetFetchFail = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
    ...state,
    widgets: { ...state.widgets, [widgetId]: { loading: false, detail: null, error: action.payload.error } },
  };
};
