import { getBrands } from '../../api/BrandApi';


export const BRANDS_FETCH = "BRANDS/fetch";
export const BRANDS_FETCH_SUCCESS = "BRANDS/fetch_success";
export const BRANDS_FETCH_FAIL = "BRANDS/fetch_fail";

export const brandsFetchSuccess = (brands) => ({ type: BRANDS_FETCH_SUCCESS, payload: brands});
export const brandsFetchFail = (error) => ({ type: BRANDS_FETCH_FAIL, payload: error });
export const brandsFetch = (limit, offset) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: BRANDS_FETCH });
      const {  data } = await getBrands(limit, offset);
      dispatch(brandsFetchSuccess(data));
    } catch(error) {
      console.log(error);
      dispatch(brandsFetchFail(error));
    }
  }
};
