import Cookies from 'js-cookie';
import { getUser, getUserServices } from '../../api/UserApi';
import { checkJwt } from '../../api/JwtApi';
import {APP_CONFIG} from "../../constants";
import jwt_decode from "jwt-decode";

export const USER_SSO_FETCH = "USER_SSO/fetch";
export const USER_SSO_FETCH_FAIL = "USER_SSO/fetch_fail";
export const USER_SSO_FETCH_SUCCESS = "USER_SSO/fetch_success";
export const USER_SERVICES_FETCH = "USER_SERVICES/fetch";
export const USER_SERVICES_FETCH_FAIL = "USER_SERVICES/fetch_fail";
export const USER_SERVICES_FETCH_SUCCESS = "USER_SERVICES/fetch_success";
export const USER_FETCH = "USER/fetch";
export const USER_FETCH_FAIL = "USER/fetch_fail";
export const USER_FETCH_SUCCESS = "USER/fetch_success";
export const JWT_CHECK_FETCH = "JWT_CHECK/fetch";
export const JWT_CHECK_FETCH_FAIL = "JWT_CHECK/fetch_fail";
export const JWT_CHECK_FETCH_SUCCESS = "JWT_CHECK/fetch_success";

export const userSSOFetch = (widget) => {
  return async (dispatch, getState) => {
    if(widget.brand){
      try {
        if (APP_CONFIG.IS_TEST && APP_CONFIG.TEST_USER_LOGGED_IN){
          var user = {
		                  "data":{
			                        "results":[{
				                          "first_name": "admin",
                                  "id": 1,
                                  "is_active": true,
                                  "last_name": "admin",
                                  "username": "admin"
				                      }]
                          }
	                    }
          dispatch(userFetchSuccess(user));
        }else{
          if (Cookies.get(widget.brand.cookie_name) || Cookies.get('TEST-'+widget.brand.cookie_name)){//toremove
            if (Cookies.get(APP_CONFIG.COOKIE_JWT_NAME)){
              const jwt = Cookies.get(APP_CONFIG.COOKIE_JWT_NAME);
              var jwtDecoded = jwt_decode(jwt);

              if(jwtDecoded!=undefined){
                if(jwtDecoded.sub!=undefined && (jwtDecoded.exp > (new Date().getTime() + 1) / 1000)) {
                  dispatch({type: JWT_CHECK_FETCH});
                  const jwt_checked = await checkJwt({'blaize': jwt})
                  if (jwt_checked.data.uid) {
                    dispatch(jwtCheckFetchSuccess(jwt_checked));

                    try {
                      dispatch({type: USER_SERVICES_FETCH});
                      var dateObj = new Date();
                      var month = ('0' + (dateObj.getUTCMonth() + 1)).slice(-2);
                      var day = ('0' + (dateObj.getUTCDate())).slice(-2);
                      var year = dateObj.getUTCFullYear();
                      var issue = year + "" + month + "" + day;
                      var params = {
                                'testata': widget.brand.name,
                                'newspaper': widget.brand.name,
                                'edition': widget.brand.name,
                                'cookie': jwt,
                                'zona': APP_CONFIG.SERVICES_ZONA,
                                'issue': issue,
                              };
                      const services = await  getUserServices( widget, params);
                      if (services.data) {
                      dispatch(userServicesFetchSuccess(services));
                      }
                    }catch (e){
                      console.log(e);
                      dispatch(userServicesFetchFail(e));
                    }

                    dispatch({type: USER_FETCH});
                    const user = await getUser({'sso_username': jwtDecoded.sub})
                    if (user.data.count) {
                      dispatch(userFetchSuccess(user));
                      // if (user.data.results[0].is_active) {
                      //   dispatch(userFetchSuccess(user));
                      // } else {
                      //   throw new Error("User is not active");
                      // }
                    } else {
                      // devo permettere comunque di accedere
                      var userTemp = {
                        "data":{
                                "results":[{
                                    "first_name": "admin",
                                    "id": 1,
                                    "is_active": true,
                                    "last_name": "admin",
                                    "username": "admin",
                                    "sso_token": jwtDecoded.sub
                                }]
                            }
                        }
                        dispatch(userFetchSuccess(userTemp));
                      //throw new Error("Error on get user's info");
                    }
                  }else{
                    throw new Error("Error jwt not valid");
                  }
                }else{
                  throw new Error("Error on read jwt");
                }
              }else{
                throw new Error("Error on get userSSO's info from jwt");
              }
            }else{
              throw new Error("User's jwt not present");
            }
          }else{
            throw new Error("User not authenticated");
          }
        }
      }catch (e){
        console.log(e);
        dispatch(userFetchFail(e));
      }
    }
  }
}

//params can be an integer (user ct) or a map (sso user -> sso_username)
export const userFetch = (params) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: USERS_FETCH });
      const user = await getUser(params)
      dispatch(userFetchSuccess(user));
    }catch (e){
      console.log(e);
      dispatch(userFetchFail(e));
    }
  }
}

export const jwtCheckFetchFail = (error) => ({type: JWT_CHECK_FETCH_FAIL, payload: error});
export const jwtCheckFetchSuccess = (jwt_checked) => ({type: JWT_CHECK_FETCH_SUCCESS, payload: jwt_checked});

export const userServicesFetchFail = (error) => ({type: USER_SERVICES_FETCH_FAIL, payload: error});
export const userServicesFetchSuccess = (services) => ({type: USER_SERVICES_FETCH_SUCCESS, payload: services});

export const userFetchFail = (error) => ({type: USER_FETCH_FAIL, payload: error});
export const userFetchSuccess = (user) => ({type: USER_FETCH_SUCCESS, payload: user});

export const userSSOFetchFail = (error) => ({type: USER_SSO_FETCH_FAIL, payload: error});
export const userSSOFetchSuccess = (userSSO) => ({type: USER_SSO_FETCH_SUCCESS, payload: userSSO});

