import {USER_FETCH_SUCCESS, USER_FETCH_FAIL,USER_SSO_FETCH_SUCCESS, USER_SSO_FETCH_FAIL,USER_SERVICES_FETCH_SUCCESS, USER_SERVICES_FETCH_FAIL} from '../actions/UserAction';

const INITIAL_STATE = {
  user: null,
  userSSO: null,
  isAuthenticated: false,
  has_services: false,
  error: null
}

const userServicesFetchSuccess = (state, action) => {
  const has_services = action.payload.data.authorized;
  return ({
    ...state,
    has_services: has_services
  })
}

const userServicesFetchFail = (state) => {
  return ({
    ...state,
    error: state,
    has_services: false
  })
}

const userSSOFetchSuccess = (state, action) => {
  const userSSO = action.payload.data.user;
  return ({
    ...state,
    userSSO: userSSO,
    isAuthenticated: true
  })
}

const userSSOFetchFail = (state) => {
  return ({
    ...state,
    error: state,
    isAuthenticated: false,
    userSSO: null,
    user: null
  })
}

const userFetchSuccess = (state, action) => {
  const user = action.payload.data.results[0];
  return ({
    ...state,
    user: user.id,
    isAuthenticated: true,
    sso_token: user.sso_token
  })
}

const userFetchFail = (state) => {
  return ({
    ...state,
    error: state,
    isAuthenticated: false,
    userSSO: null,
    user: null
  })
}

export default (state = INITIAL_STATE, action ) => {
  switch (action.type){
    case USER_SERVICES_FETCH_SUCCESS:
      return userServicesFetchSuccess(state, action);
    case USER_SERVICES_FETCH_FAIL:
      return userServicesFetchFail(state);
    case USER_SSO_FETCH_SUCCESS:
      return userSSOFetchSuccess(state, action);
    case USER_SSO_FETCH_FAIL:
      return userSSOFetchFail(state);
    case USER_FETCH_SUCCESS:
      return userFetchSuccess(state, action);
    case USER_FETCH_FAIL:
      return userFetchFail(state);
    default:
      return state;
  }
}

