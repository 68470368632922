import http, { defaultParams } from './configureApi';
import {APP_CONFIG} from "../constants";

/**
 * Brand Api
 *
 * Model:
 * {
 *  "url": "http://127.0.0.1:8000/brand/1/",
 *  "id": 1,
 *  "name": "rep-roma",
 *  "title": "Repubblica Roma",
 *  "baseurl": "https://roma.repubblica.it"
 *  "cookie_name": "CORRIEREALPISOCIAL"
 *  "ws_url": "https://social.lastampa.it/social/sites/lastampa/www/getuser.php"
 * }
 */
const BRANDS_PATH = process.env.CT_API_BRANDS_PATH
const BRAND_PATH = process.env.CT_API_BRAND_PATH
const BRAND_PATH_ID = process.env.CT_API_BRAND_PATH_ID
export const getBrands = (limit, offset) => {
  return http.get(BRANDS_PATH, {
    defaultParams, ...{
      params: {
        ...((typeof limit !== 'undefined' && limit !== null) ? { limit: limit } : { limit: AppConfig.BRANDS_DEFAULT_ITEMS }),
        ...((typeof offset !== 'undefined' && offset !== null) ? { offset: offset } : { offset: 0 }),
      },
    }
  });
};

export const getBrand = (id) => {
  return http.get(BRAND_PATH_ID.replace(":id", id));
};

export const getBrandDomain = () => {
  return (!APP_CONFIG.IS_TEST) ? window.location.origin : APP_CONFIG.TEST_BRAND_DOMAIN;
};

export const inBrand = (brand) => {
  return brand!=null && brand.baseurl ?  brand.baseurl == getBrandDomain() : false;
};

export const getCurrentBrand = (brands) => {
  let brand = brands.filter(brand => brand.baseurl == getBrandDomain());
  return (brand.length>0) ? brand[0] : null;
};
