export const getInitiativesList = ({ initiatives }) => { return initiatives.initiativesList.list.results};
export const getInitiativesListLoading = ({ initiatives }) => { return initiatives.initiativesList.loading};
export const getInitiativesCount = ( { initiatives } ) => { return initiatives.initiativesList.list.count};
export const getInitiativesPagination = ( { initiatives } ) => { return initiatives.initiativesList.pagination};
export const getInitiativeFilter = ( {initiatives} ) => { return { filter: initiatives.brandFilter }};
export const getInitiativesPreviewList = ({ initiatives }) => { return initiatives.initiativesPreviewList.results};
export const getInitiativeFormFields = ({ initiatives }) => { return initiatives.initiativeForm.customFields};
export const getInitiativeType = ({ initiatives }) => { return initiatives.initiativeType.results};
export const getInitiativeSearchKeyword = ({ initiatives}) => { return initiatives.initiativesList.searchKeyword};
export const getInitiativeModuleTypes = ({ initiatives }) => { return initiatives.initiativeModuleTypes.results};
export const getInitiativeDetail = ({ initiatives }) => { return initiatives.initiativeDetail};
