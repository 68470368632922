import {
  getInitiative
} from '../../api/InitiativeApi';

export const INITIATIVE_DETAIL_FETCH = "INITIATIVES/fetch_detail";
export const INITIATIVE_DETAIL_FETCH_SUCCESS = "INITIATIVES/fetch_detail_success";
export const INITIATIVE_DETAIL_FETCH_FAIL = "INITIATIVES/fetch_detail_fail";

/**
 *  INITIATIVES DETAIL ACTIONS
 */
export const initativeDetailFetchSuccess = (initatives) => ({ type: INITIATIVE_DETAIL_FETCH_SUCCESS, payload: initatives});
export const initativeDetailFetchFail = (error) => ({ type: INITIATIVE_DETAIL_FETCH_FAIL, payload: error });
export const initiativeDetailFetch = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: INITIATIVE_DETAIL_FETCH });
      const { data } = await getInitiative(id);
      dispatch(initativeDetailFetchSuccess(data));

    } catch(error) {
      console.log(error);
      dispatch(initativeDetailFetchFail(error));
    }
  }
};
