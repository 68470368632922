import { BRANDS_FETCH, BRANDS_FETCH_SUCCESS } from '../actions/BrandAction';


const INITIAL_STATE = {
  brands: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BRANDS_FETCH:
      return brandsFetch(state);
    case BRANDS_FETCH_SUCCESS:
      return brandsFetchSuccess(state, action);
    default:
      return state;
  }
};

const brandsFetch = (state) => {
  return ({
  ...state,
  brands: state.brands,
})};

const brandsFetchSuccess = (state, action) => {
  const widgetId = action.payload.widgetId;
  return ({
  ...state,
    brands: action.payload.results,
})};

