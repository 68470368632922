import React from 'react';
import WidgetSurvey from './survey/WidgetSurveyComponent.js';
import WidgetGallery from './gallery/WidgetGalleryComponent.js';
import WidgetSlideshow from './slideshow/WidgetSlideshowComponent.js';
import WidgetText from './text/WidgetTextComponent';
import WidgetContribute from './contribute/WidgetContributeComponent';
import WidgetProposal from './proposal/WidgetProposalComponent';
import WidgetMap from './map/WidgetMapComponent';
import WidgetRanking from './gamification/WidgetRankingComponent';

export const WidgetFactory = (props) => {

  const {widget} = props;
  const {detail: {type_obj: {name}}} = widget;

  switch (name) {
    case "survey":
      return <WidgetSurvey {...props} />;
    case "text":
      return <WidgetText {...props} />;
    case "gallery":
      return <WidgetGallery {...props} />;
    case "slideshow":
      return <WidgetSlideshow {...props} />;
    case "contribute":
      return <WidgetContribute {...props} />;
    case "proposal":
      return <WidgetProposal {...props} />;
    case "map":
      return <WidgetMap {...props} />
    case "gamification":
      return <WidgetRanking {...props} />;
    default:
      return <div>Reload...</div>;
  }
}
