import React, { Component } from 'react';
import WidgetPage from '../page/WidgetPage';

export default function App(props) {
  return (
    <React.Fragment>
      <WidgetPage {...props} />
    </React.Fragment>
  );
}
