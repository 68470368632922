import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const DialogComponent = (props) => {
  const classes = useStyles();
  const {severity, msg, open, onClose} = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <Alert variant="outlined" severity={severity}>
        {msg}
      </Alert>
    </Dialog>

  );
}

export default DialogComponent;
