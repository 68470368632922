import ReactDOM from 'react-dom';
import AppStore from './store/AppStore';
import retargetEvents from 'react-shadow-dom-retarget-events';
import React from 'react';

export class WidgetHtmlElement extends HTMLElement {
  mountPoint;
  componentAttributes = {};

  connectedCallback() {
    this.mountReactApp();
  }

  disconnectedCallback() {
    ReactDOM.unmountComponentAtNode(this.mountPoint);
  }

  static get observedAttributes() {
    return ['widgetid'];
  }

  attributeChangedCallback(name, oldVal, newVal) {
    this.componentAttributes[name] = newVal;
    this.mountReactApp();
  }

  mountReactApp() {
    if (!this.mountPoint) {
      this.mountPoint = document.createElement('div');
      this.attachShadow({ mode: 'open' })
        .appendChild(this.mountPoint);
    }

    ReactDOM.render(<AppStore { ...this.componentAttributes } />, this.mountPoint);
    retargetEvents(this.mountPoint);

  }
}
