import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getInitiativeDetail } from '../../../store/selectors/InitiativeSelector';
import {
  getUgcList,
  getUgcListPagination,
  getUgcWidgetList,
} from '../../../store/selectors/UgcSelector';
import {
  ugcListFetch,
  ugcPaginationListSet,
  ugcWidgetListFetch,
} from '../../../store/actions/UgcAction';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import { withStyles } from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import Pagination from '@material-ui/lab/Pagination';
import Grid from '@material-ui/core/Grid';
import Carousel from 'react-material-ui-carousel';

import { APP_CONFIG } from '../../../constants';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  CarouselWrapper: {
    width: '100%',
  },
  Carousel: {
    backgroundColor: '#bfc2c34d',
    minWidth: '100%',
    '& > .CarouselItem': {
      minHeight: '360px',
      maxHeight: '360px',
      '& > div > li': {
        listStyle: 'none',
      },
    },
    '.MuiGridListTile-root': {
      display: 'none',
    },
    '& > svg > circle': {
      fill: 'red',
    },
  },
  imageSlide: {
    width: '100%',
    height: 'auto',
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
});

class WidgetGallery extends Component {
  componentDidMount() {
    this.props.componentDidMount();
  }

  render() {
    const { ugcList, ugcPagination, handlePageChange } = this.props;

    const { classes } = this.props;

    if (ugcList !== undefined && ugcList.results !== undefined) {
      const itemForPage = APP_CONFIG.UGC_ITEMS_FOR_PAGE;
      const count = ugcList.count > itemForPage ? ugcList.count / itemForPage : 1;
      return (
        <Carousel
          className={classes.Carousel}
          autoPlay={false}
          navButtonsAlwaysVisible={true}
          next={(next, active) => console.log(`we left ${active}, and are now at ${next}`)}
          prev={(prev, active) => console.log(`we left ${active}, and are now at ${prev}`)}
        >
          {ugcList.results.map((ugc, index) => {
            if (ugc.modules !== undefined) {
              let moduleImages = ugc.modules.filter(module => module.image !== null);
              if (moduleImages !== undefined && moduleImages.length > 0) {
                // console.log(moduleImages);
                let gallery = moduleImages.map(imageItem => {
                  return (
                    <GridListTile
                      className={classes.item}
                      key={moduleImages[0].id}
                      cols={index || 1}
                    >
                      <img className={classes.imageSlide} src={imageItem.image} alt={index} />
                      <GridListTileBar title={imageItem.title} />
                    </GridListTile>
                  );
                });
                return gallery;
              }
            }
          })}
        </Carousel>
      );
    } else {
      // rimuovere
      return 'list ugc empty';
    }
  }
}

export default withStyles(styles, { withTheme: true })(
  connect(
    (state, props) => ({
      initiative: getInitiativeDetail(state),
      ugcList:
        props.widget.detail.ugc_related_count && props.widget.detail.ugc_related_count > 0
          ? getUgcWidgetList(state, props.widget.detail.uuid)
          : getUgcList(state, props.widget.detail.uuid),
      ugcPagination: getUgcListPagination(state, props.widget.detail.uuid),
    }),
    (dispatch, props) => ({
      componentDidMount() {
        props.widget.detail.ugc_related_count && props.widget.detail.ugc_related_count > 0
          ? dispatch(
              ugcWidgetListFetch(props.widget.detail.uuid, props.widget.detail.initiative_obj.id)
            )
          : dispatch(ugcListFetch(props.widget.detail.uuid, props.widget.detail.initiative_obj.id));
      },
      handlePageChange(event, page) {
        page = page ? page : 1;
        dispatch(ugcPaginationListSet(props.widget.detail.uuid, page));
        props.widget.detail.ugc_related_count && props.widget.detail.ugc_related_count > 0
          ? dispatch(
              ugcWidgetListFetch(props.widget.detail.uuid, props.widget.detail.initiative_obj.id)
            )
          : dispatch(ugcListFetch(props.widget.detail.uuid, props.widget.detail.initiative_obj.id));
      },
    })
  )(WidgetGallery)
);
