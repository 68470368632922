import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import initiatives from './InitiativeReducer';
import proposal from './ProposalReducer';
import ugc from './UgcReducer';
import widget from './WidgetReducer';
import brand from './BrandReducer';
import gamification from './GamificationReducer';
import user from './UserReducer';
import userSSO from './UserReducer';
import jwt from './JwtReducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    initiatives,
    proposal,
    ugc,
    widget,
    brand,
    gamification,
    user,
    userSSO,
    jwt,
});
