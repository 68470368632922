import React from 'react';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from './configureStore';
import App from '../components/App';

const store = configureStore(/* provide initial state if any */);
export default function AppStore(props) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
          <App {...props} />
      </ConnectedRouter>
    </Provider>
  );
}
