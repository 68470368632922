import React, { Component } from 'react';
import { connect } from 'react-redux';
import { widgetFetch } from '../store/actions/WidgetAction';
import { getWidget } from '../store/selectors/WidgetSelector';
import { WidgetSurvey } from '../components/widget/survey/WidgetSurveyComponent.js';
import { WidgetFactory } from '../components/widget/WidgetFactory';
import { inBrand, getCurrentBrand } from '../api/BrandApi';
import { getSSOServices, getUserSSO, getUser, getSSOToken } from '../store/selectors/UserSelector';

class WidgetPage extends Component {
  componentDidMount() {
    this.props.componentDidMount();
  }

  render() {
    const { widget } = this.props;
    if (
      widget &&
      !widget.loading &&
      (typeof widget.detail === 'undefined' || widget.detail === null)
    ) {
      console.log('Empty Widget', this.props);
      return '';
    } else if (widget && !widget.loading && Object.keys(widget.detail).length !== 0) {
      this.props.widget.detail.brand = getCurrentBrand(
        this.props.widget.detail.initiative_obj.brands
      );
      if (inBrand(widget.detail.brand)) {
        const {
          detail: {
            type_obj: { name: typeName },
          },
        } = widget;
        return <WidgetFactory {...this.props} />;
      } else {
        console.log(widget.detail);
        console.error("domain doesn't matching");
      }
      return '';
    }
    return 'Loading...';
  }
}
export default connect(
  (state, props) => ({
    widget: getWidget(state, props.widgetid),
    userSSO: getUserSSO(state, props),
    user: getUser(state, props),
    ssoToken: getSSOToken(state, props),
    ssoServices: getSSOServices(state, props)
  }),
  (dispatch, props) => ({
    componentDidMount() {
      dispatch(widgetFetch(props.widgetid));
    },
  })
)(WidgetPage);
