import http, { defaultParams } from './configureApi';

const WIDGET_PATH = process.env.CT_API_WIDGET_PATH
const WIDGET_PATH_ID = process.env.CT_API_WIDGET_PATH_ID
const BRANDS_PATH = process.env.CT_API_BRANDS_PATH

export const getWidgetByUuid = (widgetUuid) => {
  const path = WIDGET_PATH_ID.replace(":id", widgetUuid);
  return http.get(path, {
    defaultParams
  });
};
