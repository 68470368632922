import {
  INITIATIVE_DETAIL_FETCH,
  INITIATIVE_DETAIL_FETCH_FAIL,
  INITIATIVE_DETAIL_FETCH_SUCCESS
} from '../actions/InitiativeAction';

const INITIAL_STATE = {
  initiativeDetail: {
    detail: {}
  }
};

export default (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case INITIATIVE_DETAIL_FETCH:
      return {
        ...state,
        initiativeDetail: { ...state.initiativeDetail, loading: true }
      }
    case INITIATIVE_DETAIL_FETCH_FAIL:
      return {
        ...state,
        initiativeDetail: { ...state.initiativeDetail, loading: false }
      }
    case INITIATIVE_DETAIL_FETCH_SUCCESS:
      return {
        ...state,
        initiativeDetail: { ...state.initiativeDetail, ...{detail: action.payload }, ...{saving: false, loading: false} }
      }
    default:
      return state;
  }
};
