import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ugcListFetch, ugcPaginationListSet } from '../../../store/actions/UgcAction';
import { getInitiativeDetail } from '../../../store/selectors/InitiativeSelector';
import { getUgcList, getUgcListPagination } from '../../../store/selectors/UgcSelector';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Pagination from '@material-ui/lab/Pagination';
import { APP_CONFIG } from '../../../constants';
import { makeStyles } from '@material-ui/core/styles';
/**
 * @param {object} style different approach to using style due to mismatch between react versions
 */

const listItemStyle = {
  display: 'flex',
  alignItems: 'center',
};
const avatarStyle = {
  margin: 0,
};
const paginationStyle = {
  display: 'flex',
  justifyContent: 'center',
};
class WidgetText extends Component {
  componentDidMount() {
    this.props.componentDidMount();
  }

  render() {
    const useStyles = makeStyles(theme => ({
      root: {
        width: '100%',
      },
      listItem: {
        display: 'none',
      },
    }));

    const { ugcList, ugcPagination, handlePageChange, widget } = this.props;

    const {
      detail: {
        title: widgetTitle,
        description,
        user_obj,
        type_obj: { name: typeName },
        initiative_obj,
      },
    } = widget;
    const { title, summary } = initiative_obj;
    const itemForPage = APP_CONFIG.UGC_ITEMS_FOR_PAGE;
    let count = 1;
    if (ugcList !== undefined)
      count = ugcList.count > itemForPage ? ugcList.count / itemForPage : 1;

    return (
      <Container className="text_widget" padding={0}>
        <Typography className="entry_title" component="h2" variant={'h2'} display={'block'}>
          {title}
        </Typography>
        <Typography component="p" variant={'subtitle2'} display={'block'}>
          {summary}
        </Typography>

        {ugcList && (
          <List>
            {ugcList &&
              ugcList.results &&
              ugcList.results
                .filter(ugc => ugc.modules && ugc.modules.length > 0)
                .map((ugc, index) => {
                  return (
                    <ListItem
                      style={listItemStyle}
                      alignItems="flex-start"
                      key={`listime-` + index}
                    >
                      <ListItemAvatar style={avatarStyle}>
                        <Avatar
                          className="text_widget-avatar"
                          alt="avatar"
                          src="https://test.gelestatic.it/cless/common/2018-v1/img/avatar-dni.svg"
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={ugc.title}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              style={{ display: 'inline' }}
                              color="textPrimary"
                            >
                              {ugc.modules[0].value}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  );
                  <Divider variant="inset" component="li" />;
                })}
            <Pagination
              style={paginationStyle}
              count={count}
              page={ugcPagination.page}
              onChange={handlePageChange}
            />
          </List>
        )}
      </Container>
    );
  }
}

export default connect(
  (state, props) => ({
    initiative: getInitiativeDetail(state),
    ugcList: getUgcList(state, props.widget.detail.uuid),
    ugcPagination: getUgcListPagination(state, props.widget.detail.uuid),
  }),
  (dispatch, props) => ({
    componentDidMount() {
      dispatch(ugcListFetch(props.widget.detail.uuid, props.widget.detail.initiative_obj.id));
    },
    handlePageChange(event, page) {
      page = page ? page : 1;
      dispatch(ugcPaginationListSet(props.widget.detail.uuid, page));
      dispatch(ugcListFetch(props.widget.detail.uuid, props.widget.detail.initiative_obj.id));
    },
  })
)(WidgetText);
