import React, {Component, useState} from 'react';
import { connect } from 'react-redux';
import { getInitiativeDetail } from '../../../store/selectors/InitiativeSelector';
import { getUgcList, getUgcWidgetList, getUgcListPagination } from '../../../store/selectors/UgcSelector';
import { ugcListFetch, ugcWidgetListFetch, ugcPaginationListSet } from '../../../store/actions/UgcAction';
import { withStyles } from '@material-ui/core';
import Image from './Image';
import ArrowButton from "./ArrowButton";
import {APP_CONFIG} from "../../../constants";

const styles = theme => ({
  root: {
    position: 'relative',
    width: 500,
    height: ({fitToImageHeight}) => !fitToImageHeight && 500,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    height: '100%',
  },
  arrowWrapper: {},
  img: {},
});

class WidgetSlideshow extends Component {

  state = {
    //images: [],
    mouseOver: false,
    currentImage: 0,
    currentPage: 1,
    direction: 'left'
  };
  componentDidMount() {
    this.props.componentDidMount();
  }

  render () {
    const { ugcList, ugcPagination, handleMoreImages} = this.props;
    if (ugcList !== undefined && ugcList.results !== undefined) {

      const itemForPage = APP_CONFIG.UGC_ITEMS_FOR_PAGE;
      const count = ugcList.count > itemForPage ? Math.ceil(ugcList.count / itemForPage) : 1;

      const images = ugcList.results.map((ugc, index) => {
        if (ugc.modules !== undefined){
          let moduleImages = ugc.modules.filter(module => module.image !== null);
          if (moduleImages !== undefined && moduleImages.length > 0) {
            let gallery = moduleImages.map(imageItem => {
              return imageItem.image;
            });
            return gallery;
          }
        }
      });

      let options = {
        // arrows: true,
        arrowsColor: 'dimgrey',
        arrowsBgColor: 'transparent',
        arrowsBgHoverColor: '#B9B9B95E',
        alwaysShowArrows: false,
        fitToImageHeight: false,
      };

      // const {arrows} = options;
      const getNextImage = () => (this.state.currentImage + 1) % images.length;
      const getPrevImage = () => (this.state.currentImage ? this.state.currentImage : images.length) - 1;

      const handleonMouseOver = (direction) => {
        this.setState({mouseOver:direction});
      }

      const handleNextImageClick = (event) => {
        this.setState({direction:'left'});
        let nextImage = getNextImage();

        if(nextImage==0){
          let currentPage = (ugcList.next) ? this.state.currentPage+1 : 1;
          this.setState({currentPage:currentPage});
          handleMoreImages(event, currentPage);
        }
        this.setState({currentImage:nextImage});
      };

      const handlePrevImageClick = (event) => {
        this.setState({direction:'right'});
        let prevImage = getPrevImage();

        if(this.state.currentImage==0){
          let currentPage = (this.state.currentPage==1) ? count : this.state.currentPage-1;
          this.setState({currentPage:currentPage});

          if(ugcList.previous){//prev page
            prevImage = APP_CONFIG.UGC_ITEMS_FOR_PAGE-1;
          }else{//last page
            prevImage = ((ugcList.count % itemForPage)==0 ? APP_CONFIG.UGC_ITEMS_FOR_PAGE : (ugcList.count % itemForPage))-1;
          }
          handleMoreImages(event, currentPage);
        }
        this.setState({currentImage:prevImage});
      };
      const {classes} = this.props;
      const showButtons = ugcList.next||ugcList.previous;

      return(
        <div className={classes.root}
             onMouseOver={() => showButtons && handleonMouseOver(true)}
             onMouseOut={() => handleonMouseOver(false)}>
          <div className={classes.wrapper}>
            {showButtons && <ArrowButton left
                                         {...options}
                                         showArrows={this.state.mouseOver}
                                         onButtonClick={handlePrevImageClick}
                                         classes={{root: classes.arrowWrapper}}
            />}
            <Image currentImage={this.state.currentImage} src={images[this.state.currentImage]} direction={this.state.direction}
                   classes={{img: classes.img}}/>
            {showButtons && <ArrowButton right
                                         {...options}
                                         showArrows={this.state.mouseOver}
                                         onButtonClick={handleNextImageClick}
                                         classes={{root: classes.arrowWrapper}}
            />}
          </div>
        </div>
      )
    } else {
      return '';
    }
  }
}

export default withStyles(styles, {withTheme: true})(connect(
  (state, props) => ({
    initiative: getInitiativeDetail(state),
    ugcList:  ((props.widget.detail.ugc_related_count && props.widget.detail.ugc_related_count>0) ?
              getUgcWidgetList(state, props.widget.detail.uuid):
              getUgcList(state, props.widget.detail.uuid)),
    ugcPagination: getUgcListPagination(state, props.widget.detail.uuid)
  }),
  (dispatch, props) => ({
    componentDidMount() {
      (props.widget.detail.ugc_related_count && props.widget.detail.ugc_related_count>0) ?
        dispatch(ugcWidgetListFetch(props.widget.detail.uuid,  props.widget.detail.initiative_obj.id))
        :
        dispatch(ugcListFetch(props.widget.detail.uuid, props.widget.detail.initiative_obj.id));
    },
    handleMoreImages(event, page) {
      page = (page) ? page : 1;
      dispatch(ugcPaginationListSet(props.widget.detail.uuid, page));

      (props.widget.detail.ugc_related_count && props.widget.detail.ugc_related_count>0) ?
        dispatch(ugcWidgetListFetch(props.widget.detail.uuid,  props.widget.detail.initiative_obj.id))
        :
        dispatch(ugcListFetch(props.widget.detail.uuid, props.widget.detail.initiative_obj.id));
    }
  }),
)(WidgetSlideshow));
