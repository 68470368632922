import React, { Component } from 'react';
import { connect } from 'react-redux';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';

import { getInitiativeDetail } from '../../../store/selectors/InitiativeSelector';
import { getUgcList } from '../../../store/selectors/UgcSelector';
import { ugcListFetch } from '../../../store/actions/UgcAction';
import {APP_CONFIG} from "../../../constants";

class WidgetMap extends Component {
  state = {
    currentLocation: {
      lat: 41.8968348,
      lng: 12.4866357,
    },
  };

  componentDidMount() {
    this.props.componentDidMount();
    if (navigator && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(pos => {
        const coords = pos.coords;
        this.setState({
          currentLocation: {
            lat: coords.latitude,
            lng: coords.longitude,
          },
        });
      });
    }
  }

  getGeoPointOrNull = module => {
    if (module.value === undefined) return;

    let geoPointInfo = null;
    try {
      geoPointInfo = JSON.parse(module.value);
    } catch (e) {
      return;
    }

    if (!geoPointInfo) return null;

    if (geoPointInfo.position !== undefined) return geoPointInfo.position;
    else return geoPointInfo;
  };

  getGeoPoints = ugcItem => {
    if (ugcItem.modules === undefined) return null;

    const geoPoints = ugcItem.modules
      .filter(module => this.getGeoPointOrNull(module) !== undefined)
      .map(module => this.getGeoPointOrNull(module));

    return geoPoints.length > 0 ? geoPoints : null;
  };

  render() {
    const { ugcList } = this.props;
    const mapStyles = {
      width: '100%',
      height: '100%',
    };
    if (ugcList !== undefined && ugcList.results !== undefined) {
      let geoPointList = [];
      ugcList.results.forEach(ugcItem => {
        geoPointList = geoPointList.concat(this.getGeoPoints(ugcItem));
      });
      return (
        <div style={{ height: '100vh', width: '80%' }}>
          <Map
            google={this.props.google}
            zoom={14}
            style={mapStyles}
            initialCenter={this.state.currentLocation}
          >
            {geoPointList.map((geoPoint, index) => {
              return <Marker key={index} position={geoPoint} />;
            })}
          </Map>
        </div>
      );
    }
    return <p>NO geopoints</p>;
  }
}

export default connect(
  (state, props) => ({
    initiative: getInitiativeDetail(state),
    ugcList: getUgcList(state, props.widget.detail.uuid),
  }),
  (dispatch, props) => ({
    componentDidMount() {
      dispatch(ugcListFetch(props.widget.detail.uuid, props.widget.detail.initiative_obj.id));
    },
  })
)(
  GoogleApiWrapper({
    apiKey: APP_CONFIG.GOOGLE_KEY,
  })(WidgetMap)
);
