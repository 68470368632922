import { deleteProposal, getProposal, getProposals, modifyProposal, createProposal } from '../../api/ProposalApi';


export const PROPOSAL_DETAIL_FETCH = "PROPOSALS/detail_fetch";
export const PROPOSAL_DETAIL_FETCH_SUCCESS = "PROPOSALS/detail_fetch_success";
export const PROPOSAL_DETAIL_FETCH_FAIL = "PROPOSALS/detail_fetch_fail";
export const PROPOSAL_SAVE = "PROPOSALS/save";
export const PROPOSAL_SAVE_SUCCESS = "PROPOSALS/save_success";
export const PROPOSAL_SAVE_FAIL = "PROPOSALS/save_fail";

/**
 *  PROPOSALS DETAIL ACTIONS
 */
export const proposalDetailFetchSuccess = (proposals) => ({ type: PROPOSAL_DETAIL_FETCH_SUCCESS, payload: proposals});
export const proposalDetailFetchFail = (error) => ({ type: PROPOSAL_DETAIL_FETCH_FAIL, payload: error });
export const proposalDetailFetch = (id) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_DETAIL_FETCH });
      const { data } = await getProposal(id);
      dispatch(proposalDetailFetchSuccess(data));

    } catch(error) {
      console.log(error);
      dispatch(proposalDetailFetchFail(error));
    }
  }
};

export const proposalSave = (proposal) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: PROPOSAL_SAVE });
      const { data } = await createProposal(proposal);
      return dispatch(proposalSaveSuccess(data));
    }catch (error) {
      return dispatch(proposalSaveFail(error));
    }
  }
}
export const proposalSaveSuccess = (data) => ({ type: PROPOSAL_SAVE_SUCCESS, payload: data});
export const proposalSaveFail = (error) => ({ type: PROPOSAL_SAVE_FAIL, payload: error});
