import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WidgetFieldFactory from './WidgetFieldFactory';
import Button from '@material-ui/core/Button';
import { Label } from '@material-ui/icons';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& .email': {
      flex: ' 1 1 auto',
      margin: theme.spacing(1),
    },
    '& .short_text': {
      flex: ' 1 1 auto',
      margin: theme.spacing(1),
    },
    '& .long_text': {
      margin: theme.spacing(1),
    },
    padding: '0px',
  },
  title: {
    padding: '8px 24px',
    color: '#3B84B5',
    fontSize: '18px',
  },

  fieldset: {
    border: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& .MuiInputLabel-formControl': {
      color: '#3B84B5',
    },
    '& .MuiInput-underline:after': {
      borderColor: '#3B84B5',
    },
    '& p.errorMsg': {
      color: '#f44336',
      fontSize: '12px',
    },
  },
  embedActions: {
    width: '100%',
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > span': {
      color: '#3c84b6',
      marginRight: 'auto',
    },
  },
  delete: {
    backgroundColor: '#515151',
    color: '#fff',
    margin: '8px',
  },
  send: {
    backgroundColor: '#3B84B5',
    color: '#fff',
    margin: '8px',
  },
}));

const EmbedFormContribute = ({
  formikProps,
  error,
  handleClose,
  widget,
  disabledForm,
  type,
  pointFactor,
}) => {
  const classes = useStyles();

  return (
    <Container className={classes.root + ' ' + 'embed_contribute-form'}>
      <fieldset className={classes.fieldset} disabled={disabledForm}>
        {error && 'Oops! Si è verificato un errore'}
        {error === null && (
          <>
            <WidgetFieldFactory widgettype={type} widget={widget} {...formikProps} />
          </>
        )}

        <div className={classes.embedActions + ' ' + 'form-actions'}>
          <span>Questo contributo ti fa acquisire {pointFactor} punto</span>

          <Button className={classes.delete} size="medium" onClick={handleClose} color="primary">
            ANNULLA
          </Button>
          <Button
            className={classes.send}
            size="medium"
            onClick={formikProps.handleSubmit}
            color="primary"
          >
            INVIA
          </Button>
        </div>
      </fieldset>
    </Container>
  );
};

export default EmbedFormContribute;
