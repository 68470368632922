import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WidgetFieldFactory from './WidgetFieldFactory';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles(theme => ({
  root: {
    color: '#3B84B5',
    '& .short_text': {
      flex: ' 1 1 auto',
      margin: theme.spacing(1),
    },
    '& .email': {
      flex: ' 1 1 auto',
      margin: theme.spacing(1),
    },
    '& .long_text': {
      margin: theme.spacing(1),
    },
  },
  title: {
    padding: '8px 24px',
    color: '#3B84B5',
    fontSize: '18px',
  },
  dialogActions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > span': {
      color: '#3c84b6',
      marginRight: 'auto',
    },
  },
  footerText: {
    color: '#3c84b6',
    fontSize: '14px',
    marginTop: '24px',
  },
  fieldset: {
    border: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    padding: '24px',
    justifyContent: 'space-between',
    '& .MuiInputLabel-formControl': {
      color: '#3B84B5',
    },
    '& .MuiInput-underline:after': {
      borderColor: '#3B84B5',
    },
  },
  actions: {
    color: '#3B84B5',
  },
}));

const DialogFormContribute = ({
  open,
  formikProps,
  error,
  handleClose,
  widget,
  disabledForm,
  type,
  pointFactor,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Dialog
        className={classes.root}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle className={classes.title} id="form-dialog-title">
          Descrivici il tuo contributo
        </DialogTitle>
        <fieldset
          className={classes.fieldset + ' ' + 'dialog_contribute-form'}
          disabled={disabledForm}
        >
          {error && 'Oops! Si è verificato un errore'}
          {error === null && (
            <>
              <WidgetFieldFactory widgettype={type} widget={widget} {...formikProps} />
            </>
          )}

          <div className={classes.dialogActions + ' ' + 'form-actions'}>
            <span className={classes.points}>
              {' '}
              Questo contributo ti fa acquisire {pointFactor} punto
            </span>

            <Button onClick={handleClose} className={classes.actions}>
              Annulla
            </Button>
            <Button onClick={formikProps.handleSubmit} className={classes.actions}>
              Invia
            </Button>
          </div>
          <p className={classes.footerText}>
            Le immagini ricevute verranno pubblicate sul sito, previa approvazione della redazione.
            Vi invitiamo, quindi, ad inviare immagini adatte al contesto e che non urtino la
            sensibilità degli altri lettori.
          </p>
        </fieldset>
      </Dialog>
    </React.Fragment>
  );
};

export default DialogFormContribute;
