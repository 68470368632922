import http, { defaultParams } from './configureApi';

const UGC_PATH = process.env.CT_API_UGC_PATH;
const UGC_LIST_PATH = process.env.CT_API_UGC_LIST_PATH;
const UGC_PATH_ID = process.env.CT_API_UGC_PATH_ID;
const UGC_MODULE_PATH = process.env.CT_API_UGC_MODULE_PATH

export const getUgcList = (initiativeId, limit, offset, params) => {
  return http.get(UGC_PATH, {
    defaultParams, ...{
      params: {
        initiative: initiativeId,
        // moderated: true,
        ...((typeof limit !== 'undefined' && limit !== null) ? { limit: limit } : { limit: 10 }),
        ...((typeof offset !== 'undefined' && offset !== null) ? { offset: offset } : { offset: 0 }),
        ...params,
      },
    },
  });
};
export const getUgcWidgetList = (widgetId, limit, offset, params) => {
  return http.get(UGC_LIST_PATH, {
    defaultParams, ...{
      params: {
        widgetuuid: widgetId,
        ...((typeof limit !== 'undefined' && limit !== null) ? { limit: limit } : { limit: 10 }),
        ...((typeof offset !== 'undefined' && offset !== null) ? { offset: offset } : { offset: 0 }),
        ...params,
      },
    },
  });
};

export const createUgc = (ugc) => {
  try {
    return http(UGC_PATH, {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
      data: ugc,
    });
  } catch (e) {
    throw e;
  }
};

export const modifyUgc = (ugc) => {
  delete ugc.brand_obj;
  delete ugc.initiative_obj;
  delete ugc.user_obj;
  try {
    return http(UGC_PATH_ID.replace(":id", ugc.id), {
      method: 'PUT',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
      data: ugc
    });
  } catch (e) {
    throw e;
  }
};

export const createUgcModule = (ugcModule) => {
  try {
    return http(UGC_MODULE_PATH, {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
      data: mapFormData(ugcModule),
    });
  } catch (e) {
    throw e;
  }
};

const mapFormData = (obj) => {
  let data = new FormData();
  for (const key in obj ) {
    const value = obj[key];
    if(Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        data.append(key+'['+i+']', value[i]);
      }
    } else {
      data.append(key, obj[key]);
    }
  }
  console.log(data);
  return data;
}
