import axios from 'axios';
import { APP_CONFIG } from '../constants';

export default axios.create({
  baseURL: APP_CONFIG.BASE_URL,
  timeout: APP_CONFIG.REQUEST_TIMEOUT
})

export const defaultParams = {
  //mode: 'no-cors',
  params: {
    format: 'json',
  }
}
