import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ShareIcon from '@material-ui/icons/Share';

// import CardHeader from '@material-ui/core/CardHeader';
// import Avatar from '@material-ui/core/Avatar';
// import FavoriteIcon from '@material-ui/icons/Favorite';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'inherit',
    '&.contribute': {
      '& > h2': {
        color: '#FFDF33',
        padding: '8px 16px',
        fontWeight: 'bold',
        margin: '0',
      },
    },
    '&.proposal': {
      padding: '16px',
      '& > h2': {
        color: '#FFDF33',
        width: '30%',
        margin: '0',
      },
    },
    borderRadius: '0',
    background: '#3B84B5',
    overflow: 'visible',
  },
  media: {
    height: 0,
    paddingTop: '56.25%',
  },
  CardContent: {
    '&.contribute': {
      color: '#ffffff',
      fontWeight: 'bold',
      opacity: '0.8',
      padding: '8px 16px',
    },
    '&.proposal': {
      fontSize: '18px',
      color: '#ffffff',
      opacity: '0.8',
      padding: '0',
    },
    '& > span': {
      fontSize: '10px',
      marginTop: '20px',
    },
  },
  actionsCnt: {
    justifyContent: 'space-between',
    padding: '0',

    '& > .proposal__cta': {
      backgroundColor: '#70B3E7',
      color: '#ffffff',
      fontSize: '12px',
      fontFamily: 'inherit',
    },
    '& > .contribute__cta': {
      color: '#000000',
      backgroundColor: '#FFDF33',
      borderRadius: '4px',
      top: '25px',
      left: '14px',
      fontFamily: 'inherit',
    },
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  shareIcon: {
    color: '#ffffff',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

const RecipeReviewCard = ({ title, image, description, cta, pointFactor, widgetName }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Card className={'entry__content' + ' ' + classes.root + ' ' + widgetName}>
        <h2 className="entry_title">{title}</h2>
        {image && <CardMedia className={classes.media} image={image} />}
        <CardContent className={classes.CardContent + ' ' + widgetName}>
          <p className={classes.description}>
            {description}
            <br />
          </p>
          {/* <Typography className={classes.description} component="p">
            {description}
            <br />
            {widgetName != 'proposal' && (
              <span>Questo contribute ti fa acquisire {pointFactor} punti</span>
            )}
          </Typography> */}
        </CardContent>

        <CardActions className={classes.actionsCnt}>
          {cta}
          {widgetName != 'proposal' && (
            <IconButton className={classes.shareIcon} aria-label="share">
              {' '}
              <ShareIcon />
            </IconButton>
          )}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>Lorem ipsum sin dolor amet</Typography>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
};

export default RecipeReviewCard;
