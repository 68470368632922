import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { proposalSave } from '../../../store/actions/ProposalAction';
import moment from 'moment';
import DialogProposalComponent from './DialogProposalComponent';
import EmbedProposalComponent from './EmbedProposalComponent';
import DialogComponent from '../../dialog/DialogComponent';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '@material-ui/core/Button';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import OpenDialogProposalForm from '../card/WidgetCard';
import { jwtFilters, socialLogin } from '../../../api/UserApi';
import { PROPOSAL_SAVE_FAIL, PROPOSAL_SAVE_SUCCESS } from '../../../store/actions/ProposalAction';
import { userSSOFetch, userSSOFetchFail } from '../../../store/actions/UserAction';
import { Spinner } from '../../layout/Spinner';
import OpenContributeForm from '../card/WidgetCard';
import DialogFormContribute from '../contribute/DialogFormContribute';
import EmbedFormContribute from '../contribute/EmbedFormContribute';

const WidgetProposal = ({ user, ssoToken, ssoServices, widget, widgettype }) => {
  const [dialogMsg, setDialogMsg] = useState('');
  const [dialogSeverity, setDialogSeverity] = useState('success');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [title, setTitle] = useState('');
  const [summary, setSummary] = useState('');
  const [disabledForm, setDisabledForm] = useState('');

  const [open, setOpen] = useState(false);

  const dispatch = useDispatch();

  const handleTitleChange = event => {
    setTitle(event.target.value);
  };

  const handleSummaryChange = event => {
    setSummary(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };
  const handleOnClick = value => {
    socialLogin();
  };

  const printDialog = payload => {
    if (payload.type != PROPOSAL_SAVE_SUCCESS) {
      setDialogSeverity('error');
      setDialogMsg('Qualcosa è andato storto! Riprova!');
      setDisabledForm('');
    } else {
      setDialogSeverity('success');
      setDialogMsg('Proposta inviata! Grazie!');
      setShowForm(true);

      setTimeout(function () {
        setDialogOpen(false);
        setOpen(false);
      }, 500);
    }
    setDialogOpen(true);
  };

  let SignupSchema = Yup.object().shape({
    summary: Yup.string().required('Campo obbligatorio'),
  });

  const handleSubmit = (values, event) => {
    setDisabledForm('disabled');
    const proposal = {
      user: user,
      user_subscriber: ssoServices,
      user_registered: true,
      sso_username: ssoToken,
      brand: widget.detail.initiative_obj.brands[0],
      creation_date: moment().format(moment.HTML5_FMT.DATETIME_LOCAL),
      title: values.title,
      //title: 'proposal title',
      summary: values.summary,
      approved: false,
    };
    dispatch(proposalSave(proposal)).then(data => printDialog(data));
  };

  jwtFilters('GeleSocial.getJWT.success', dispatch, userSSOFetch, widget);

  var isUserEnabled =
    !widget.detail.initiative_obj.to_subscribers ||
    (widget.detail.initiative_obj.to_subscribers && ssoServices);

  return (
    <>
      <DialogComponent
        msg={dialogMsg}
        severity={dialogSeverity}
        open={dialogOpen}
        onClose={handleClose}
      />
      {user != null && typeof user != 'undefined' ? (
        <>
          {showForm && (
            <>
              {isUserEnabled ? (
                <>
                  <Formik
                    initialValues={{
                      title: '',
                      summary: '',
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={handleSubmit}
                  >
                    {props =>
                      widgettype === 'dialog' ? (
                        <>
                          <OpenDialogProposalForm
                            title={'Come Together'}
                            description={'Hai un approfondimento da suggerirci?'}
                            widgetName={widget.detail.type_obj.name}
                            cta={
                              <Button
                                variant="contained"
                                className="proposal__cta"
                                onClick={handleClickOpen}
                                endIcon={<ArrowForwardIosIcon />}
                              >
                                Inviaci le tue proposte
                              </Button>
                            }
                          />
                          <DialogProposalComponent
                            open={open}
                            handleClose={handleClose}
                            title={title}
                            summary={summary}
                            disabledForm={disabledForm}
                            {...props}
                          />
                        </>
                      ) : (
                        <EmbedProposalComponent
                          title={title}
                          summary={summary}
                          disabledForm={disabledForm}
                          {...props}
                        />
                      )
                    }
                  </Formik>
                </>
              ) : (
                <>
                  <OpenDialogProposalForm
                    title={'Come Together'}
                    description={'Hai un approfondimento da suggerirci?'}
                    widgetName={widget.detail.type_obj.name}
                    cta={
                      <Button
                        variant="contained"
                        color="primary"
                        href={widget.detail.brand.login_url}
                        className="proposal__cta"
                      >
                        Abbonati
                      </Button>
                    }
                  />
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <OpenDialogProposalForm
            title={'Come Together'}
            description={'Hai un approfondimento da suggerirci?'}
            widgetName={widget.detail.type_obj.name}
            cta={
              <Button
                variant="contained"
                color="primary"
                onClick={handleOnClick}
                className="proposal__cta"
              >
                Accedi
              </Button>
            }
          />
          {/* <p>{widget.detail.title}</p> */}
        </>
      )}
    </>
  );
};

export default WidgetProposal;
