import http from './configureApi';
import defaultParams from './configureApi';

const PROPOSALS_PATH_ID = process.env.CT_API_PROPOSALS_PATH_ID;
const PROPOSALS_PATH = process.env.CT_API_PROPOSALS_PATH;


export const getProposal = (id) => {
  return http.get(PROPOSALS_PATH_ID.replace(":id", id), defaultParams);
};

export const createProposal = (proposal) => {
  try {
    return http(PROPOSALS_PATH, {
      method: 'POST',
      mode: 'no-cors',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin',
      data: proposal,
    });
  } catch (e) {
    throw e;
  }
}
