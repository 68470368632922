import {JWT_CHECK_FETCH_FAIL,JWT_CHECK_FETCH_SUCCESS} from '../actions/JwtAction';

const INITIAL_STATE = {
  isAuthenticated: false,
  error: null
}

const jwtCheckFetchSuccess = (state, action) => {
  return ({
    ...state,
    isAuthenticated: true
  })
}

const jwtCheckFetchFail = (state) => {
  return ({
    ...state,
    error: state,
    isAuthenticated: false
  })
}

export default (state = INITIAL_STATE, action ) => {
  switch (action.type){
    case JWT_CHECK_FETCH_SUCCESS:
      return jwtCheckFetchSuccess(state, action);
    case JWT_CHECK_FETCH_FAIL:
      return jwtCheckFetchFail(state);
    default:
      return state;
  }
}
