import Cookies from 'js-cookie';
import { getUser } from '../../api/UserApi';
import {APP_CONFIG} from "../../constants";
import jwt_decode from "jwt-decode";

export const JWT_CHECK_FETCH = "JWT_CHECK/fetch";
export const JWT_CHECK_FETCH_FAIL = "JWT_CHECK/fetch_fail";
export const JWT_CHECK_FETCH_SUCCESS = "JWT_CHECK/fetch_success";


export const jwtCheckFetch = (params) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: JWT_CHECK_FETCH });
      const jwt_checked = await checkJwt(params)
      dispatch(jwtCheckFetchSuccess(jwt_checked));
    }catch (e){
      console.log(e);
      dispatch(jwtCheckFetchFail(e));
    }
  }
}

export const jwtCheckFetchFail = (error) => ({type: JWT_CHECK_FETCH_FAIL, payload: error});
export const jwtCheckFetchSuccess = (jwt_checked) => ({type: JWT_CHECK_FETCH_SUCCESS, payload: jwt_checked});
