import { getWidgetByUuid } from '../../api/WidgetApi';
import {userSSOFetch} from "./UserAction";

export const WIDGET_FETCH = "WIDGETS/fetch_widget";
export const WIDGET_FETCH_SUCCESS = "WIDGETS/fetch_widget_success";
export const WIDGET_FETCH_FAIL = "WIDGETS/fetch_widget_fail";

export const widgetFetchSuccess = (widgetId, widgets) => (
  { type: WIDGET_FETCH_SUCCESS, payload: {widget:widgets, widgetId: widgetId}}
);
export const widgetFetchFail = (widgetId, error) => (
  { type: WIDGET_FETCH_FAIL, payload: { error, widgetId } }
 );
export const widgetFetch = (widgetId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: WIDGET_FETCH, payload: {widgetId}});
      const {  data } = await getWidgetByUuid(widgetId);

      dispatch(widgetFetchSuccess(widgetId, data))
      dispatch(userSSOFetch(data.results[0]));

    } catch(error) {
      console.log(error);
      dispatch(widgetFetchFail(widgetId, error));
    }
  }
};


