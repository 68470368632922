import {
  PROPOSAL_DETAIL_FETCH,
  PROPOSAL_DETAIL_FETCH_SUCCESS, PROPOSAL_SAVE_FAIL, PROPOSAL_SAVE_SUCCESS
} from '../actions/ProposalAction';


const INITIAL_STATE = {
  proposalDetail: null,
  saved: false
};

export default (state=INITIAL_STATE, action) => {
  switch (action.type) {
    case PROPOSAL_DETAIL_FETCH:
      return proposalDetailFetch(state, action);
    case PROPOSAL_DETAIL_FETCH_SUCCESS:
      return proposalModifyFetchSuccess(state, action);
    case PROPOSAL_SAVE_SUCCESS:
      return proposalSaveSuccess(state, action);
    case PROPOSAL_SAVE_FAIL:
      return proposalSaveFail(state, action);
    default:
      return state;
  }
};

const proposalDetailFetch = (state, action) => ({
  ...state,
  proposalDetail: state.proposalDetail
});

const proposalModifyFetchSuccess = (state, action) => ({
  ...state,
  proposalDetail: action.payload
});

const proposalSaveSuccess = (state, action) => ({
  ...state,
  saved: true
})

const proposalSaveFail = (state, action) => ({
  ...state,
  saved: false
})
