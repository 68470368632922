import thunk from 'redux-thunk';

import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createRootReducer from './reducers';
import { __DEV__ } from '../constants';

const composeEnhancers = (__DEV__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ?
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ :
    compose;

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {

    const store = createStore(
        createRootReducer(history),
        preloadedState,
        composeEnhancers(applyMiddleware(
            routerMiddleware(history),
            thunk
        ))
    );

    return store;
}
