import {
  UGC_LIST_FETCH,
  UGC_LIST_FETCH_FAIL,
  UGC_LIST_FETCH_SUCCESS,
  UGC_WIDGET_LIST_FETCH,
  UGC_WIDGET_LIST_FETCH_FAIL,
  UGC_WIDGET_LIST_FETCH_SUCCESS,
  UGC_PAGINATION_LIST_SET, UGC_SAVE, UGC_SAVE_FAIL, UGC_SAVE_SUCCESS,
} from '../actions/UgcAction';
import { APP_CONFIG } from '../../constants';

export const UGC_DEFAULT = {
  loading: false,
  data: {},
  pagination: {
    offset: 0,
    limit: APP_CONFIG.UGC_ITEMS_FOR_PAGE,
    page: 1,
    itemsForPage: 10,
  },
};

const INITIAL_STATE = {
  widgetUgc: {},
  saveUgc: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UGC_LIST_FETCH_FAIL:
      return ugcListFetchFail(state, action);
    case UGC_LIST_FETCH:
      return ugcListFetch(state, action);
    case UGC_LIST_FETCH_SUCCESS:
      return ugcListFetchSuccess(state, action);
    case UGC_WIDGET_LIST_FETCH_FAIL:
      return ugcWidgetListFetchFail(state, action);
    case UGC_WIDGET_LIST_FETCH:
      return ugcWidgetListFetch(state, action);
    case UGC_WIDGET_LIST_FETCH_SUCCESS:
      return ugcWidgetListFetchSuccess(state, action);
    case UGC_PAGINATION_LIST_SET:
      return ugcPaginationListSet(state, action);
    case UGC_SAVE:
      return ugcListFetchFail(state, action);
    case UGC_SAVE_SUCCESS:
      return ugcListFetch(state, action);
    case UGC_SAVE_FAIL:
      return ugcListFetchSuccess(state, action);
    default:
      return state;
  }
};

const ugcListFetchFail = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
  widgetUgc: { ...state.widgetUgc, [widgetId]: UGC_DEFAULT },
}};

const ugcListFetch = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
  widgetUgc: {
    ...state.widgetUgc,
    [widgetId]: {...state.widgetUgc[widgetId], loading: UGC_DEFAULT.loading, data: UGC_DEFAULT.data }
    },
}};

const ugcListFetchSuccess = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
  widgetUgc: {
    ...state.widgetUgc,
    [widgetId]: {...state.widgetUgc[widgetId], loading: false, data: action.payload.ugcList }
  },
}};

const ugcWidgetListFetchFail = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
  widgetUgc: { ...state.widgetUgc, [widgetId]: UGC_DEFAULT },
}};

const ugcWidgetListFetch = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
  widgetUgc: {
    ...state.widgetUgc,
    [widgetId]: {...state.widgetUgc[widgetId], loading: UGC_DEFAULT.loading, data: UGC_DEFAULT.data }
    },
}};

const ugcWidgetListFetchSuccess = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
  ...state,
  widgetUgc: {
    ...state.widgetUgc,
    [widgetId]: {...state.widgetUgc[widgetId], loading: false, data: action.payload.ugcWidgetList }
  },
}};

const ugcPaginationListSet = (state, action) => {
  if (action.payload) {
    const itemsForPage = APP_CONFIG.UGC_ITEMS_FOR_PAGE;
    const {page, widgetId} = action.payload;
    const pagination = {
      offset: (page - 1) * itemsForPage,
      limit: itemsForPage,
      page: page,
      itemsForPage: itemsForPage,
    };
    return {
      ...state,
      widgetUgc: {
        ...state.widgetUgc,
        [widgetId]: {...state.widgetUgc[widgetId], pagination:pagination }
      }
    };
  }
  return state;
};


const ugcSaveFail = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
    ...state,
    saveUgc: { ...state.saveUgc, [widgetId]: { saving: false, detail:null, error: true } },
  }};

const ugcSave = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
    ...state,
    saveUgc: { ...state.saveUgc, [widgetId]: { saving: true, detail: null, error: false} },
  }};

const ugcSaveSuccess = (state, action) => {
  const widgetId = action.payload.widgetId;
  return {
    ...state,
    saveUgc: { ...state.saveUgc, [widgetId]: { saving: true, detail: action.payload.ugc, error: false} },
  }};
