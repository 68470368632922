export const getUserSSO = ({ userSSO }, widgetId) => {
  return userSSO.userSSO;
};

export const getSSOServices = ({ user }, widgetId) => {
  if ("has_services" in user)
    return Boolean(user.has_services)
  return null;
};

export const getUser = ({ user }, widgetId) => {
  return user.user;
};

export const getSSOToken = ({ user }, widgetId) => {
  if ("sso_token" in user)
    return user.sso_token
  return null;
};
