import { createUgc, createUgcModule, getUgcList, getUgcWidgetList, modifyUgc } from '../../api/UgcApi';
import { getUgcListPagination } from '../selectors/UgcSelector';

export const UGC_LIST_FETCH = "UGC_LIST/fetch";
export const UGC_LIST_FETCH_SUCCESS = "UGC_LIST/fetch_success";
export const UGC_LIST_FETCH_FAIL = "UGC_LIST/fetch_fail";
export const UGC_WIDGET_LIST_FETCH = "UGC_WIDGET_LIST/fetch";
export const UGC_WIDGET_LIST_FETCH_SUCCESS = "UGC_WIDGET_LIST/fetch_success";
export const UGC_WIDGET_LIST_FETCH_FAIL = "UGC_WIDGET_LIST/fetch_fail";
export const UGC_PAGINATION_LIST_SET = "UGC_LIST/pagination_set";

export const UGC_SAVE_FAIL = "UGC/save_fail";
export const UGC_SAVE = "UGC/save";
export const UGC_SAVE_SUCCESS = "UGC/save_success";


export const ugcListFetchSuccess = (widgetId, ugcList) => ({ type: UGC_LIST_FETCH_SUCCESS, payload: { ugcList,  widgetId}});
export const ugcListFetchFail = (widgetId, error) => ({ type: UGC_LIST_FETCH_FAIL, payload: {error, widgetId} });
export const ugcListFetch = (widgetId, ugcId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UGC_LIST_FETCH, payload: {widgetId}});
      const {limit, offset} = getUgcListPagination(getState(), widgetId);
      const { data: list } = await getUgcList(ugcId, limit, offset, {moderated: true} );
      dispatch(ugcListFetchSuccess(widgetId, list));
    } catch(error) {
      console.log(error);
      dispatch(ugcListFetchFail(widgetId, error));
    }
  }
};

export const ugcWidgetListFetchSuccess = (widgetId, ugcWidgetList) => ({ type: UGC_WIDGET_LIST_FETCH_SUCCESS, payload: { ugcWidgetList,  widgetId}});
export const ugcWidgetListFetchFail = (widgetId, error) => ({ type: UGC_WIDGET_LIST_FETCH_FAIL, payload: {error, widgetId} });
export const ugcWidgetListFetch = (widgetId, ugcId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UGC_WIDGET_LIST_FETCH, payload: {widgetId}});
      const {limit, offset} = getUgcListPagination(getState(), widgetId);
      const { data: list } = await getUgcWidgetList(widgetId, limit, offset, {moderated: true} );
      dispatch(ugcWidgetListFetchSuccess(widgetId, list));
    } catch(error) {
      console.log(error);
      dispatch(ugcWidgetListFetchFail(widgetId, error));
    }
  }
};

export const ugcPaginationListSet = (widgetId, page) => ({
  type: UGC_PAGINATION_LIST_SET,
  payload: {widgetId, page} });


export const ugcSaveSuccess = (ugc, widgetId) => ({ type: UGC_SAVE_SUCCESS, payload: {ugc, widgetId}});
export const ugcSaveFail = (error, widgetId) => ({ type: UGC_SAVE_FAIL, payload: {error, widgetId} });
export const ugcSave = (ugc, widgetId) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: UGC_SAVE, payload: {widgetId} });
      const { data } = await createUgc(ugc);
      return dispatch(ugcSaveSuccess(data, widgetId));
    } catch(error) {
      console.log(error);
      return dispatch(ugcSaveFail(error, widgetId));
    }
  }
};
