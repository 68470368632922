import React, { useState } from 'react';
import MapPicker from 'react-google-map-picker'
import {APP_CONFIG} from "../../../constants";


const DefaultLocation = { lat: 41.8968348, lng: 12.4866357};
const DefaultZoom = 10;

const Geolocation = ({name, setFieldValue, values}) => {

  const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);

  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [address, setAddress] = useState("Select an address");


  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  }
  const handleLocationChange = (lat, lng) => {
    setLocation({lat:lat, lng:lng});
    setFieldValue(name, JSON.stringify({lat, lng}));
  };
    return (
      <div>
        <h1>{address}</h1>
        <div>

          <MapPicker defaultLocation={defaultLocation}
            style={{height:'700px'}}
            onChangeLocation={handleLocationChange}
            onChangeZoom={handleChangeZoom}
            apiKey={APP_CONFIG.GOOGLE_KEY}
            language='it'
          />
        </div>
      </div>
    )

}
export default Geolocation;
